// const preUrl = 'https://api.spiritx.tk'

//export const frontEndBaseUrl = preUrl + ':8001'
export const frontEndBaseUrl = 'https://jadcup.gradspace.org'
// const preUrl = 'https://api.nzcups.co.nz'

const preUrl = 'https://apijadcup.gradspace.org'
const urlWithPort = preUrl
// const preUrl = 'http://localhost'
// const urlWithPort = preUrl + ':5020'
export const baseUrl = urlWithPort + '/api/'
