import { getColModelItem } from '../../../services/lib/utils/helpers'
import { urlKey } from '../../../services/api/api-urls'
import { Console } from 'console';

export const colKey: any = {
  productName: 'productName',
  productCode: 'productCode',
  baseProductId: urlKey.BaseProduct,
  plain: 'plain',
  logoType: 'logoType',
  logoUrl: 'logoUrl',
  showImgUrl:'showImgUrl',
  description: 'description',
  customerId: urlKey.Customer,
  plateTypeId: urlKey.PlateType,
  marginOfError: 'marginOfError',
  minOrderQuantity: 'minOrderQuantity',
  productImage: 'images',
  productMsl: 'productMsl',
  semiMsl: 'semiMsl',
  inStock: 'inStock',
  palletStacking: urlKey.PalletStacking
}


const keyInfosArray: any = () => {
  return [
    {key: colKey.productName, label: 'Name', otherOptions: {type: 'inputTextArea',required: true, width: '36rem', isNotCutting: true}},
    {key: colKey.productCode, label: 'Product Code', otherOptions: {required: true}},
    {key: colKey.customerId, label: 'Customer', otherOptions: {type: 'select', isOverrideSelectionOptions: true, valueJoinArray: ['company', 'customerCode'], width: '36rem'}},
    {key: colKey.baseProductId, label: 'Base Product', otherOptions: {type: 'select', width: '36rem'}},
    {key: colKey.palletStacking, label: 'Pallet Stacking', otherOptions: { type: 'select'}},
    {key: colKey.description, label: 'Description', otherOptions: {type: 'inputTextArea'}},
    {key: colKey.plateTypeId, label: 'Pallet Type', otherOptions: {type: 'select'}},
    {key: colKey.inStock, label: 'inStock[-1:Out of stock]', otherOptions: {type: 'inputNumberWithRange', min:0 , max:1}},
    {key: colKey.marginOfError, label: 'Default Margin of Error[%]'},
    {key: colKey.minOrderQuantity, label: 'MOQ for Order[Cartons]', otherOptions: {type: 'inputNumber'}},
    {key: colKey.productMsl, label: 'Product Stock Level[Pieces]', otherOptions: {type: 'inputNumber'}},
    {key: colKey.semiMsl, label: 'Semi Stock Level[Pieces]', otherOptions: {type: 'inputNumber'}},
    {key: colKey.productImage, label: 'Product Image', otherOptions: {type: 'image'}},
    {key: colKey.logoUrl, label: 'Logo File', otherOptions: {type: 'image'}},
    {key: colKey.showImgUrl, label: 'Show Image', otherOptions: {type: 'image'}},    
  ]
}//Coffee Dynamic Ltd
export const myfiler=(
  filterValue:any,
  rowData:any
) => {
  
  const filter = filterValue.replace(/-/g, '\u2011').trim();
  const prodName = (rowData.productName?.replace(/-/g, '\u2011'))?.toUpperCase();
  // console.log("myfiler",filterValue,prodName);
  // console.log("myfiler",filterValue.toUpperCase(),prodName);
  // console.log("myfiler len",prodName?.indexOf(filter.toUpperCase()));
  // if (prodName.toUpperCase().includes("CREAM EATERY ‑ GREASEPROOF PAPER 300X300MM"))
  //     debugger
  let result ;
  const words = filter.toUpperCase().split(" ");
  result = words.every((word: any) => prodName?.includes(word));
  if (result) return true;
  const prodCode = (rowData.productCode?.replace(/-/g, '\u2011'))?.toUpperCase();
  result = words.every((word: any) => prodCode?.includes(word));
  if (result) return true;
  const baseProdCode = (rowData.baseProduct.baseProductName?.replace(/-/g, '\u2011'))?.toUpperCase();
  result = words.every((word: any) => baseProdCode?.includes(word));
  if (result) return true;

  // if (baseProdCode?.indexOf(filter.toUpperCase()) >=0) return true;  
  // console.log(rowData,rowData.customer?.company,filterValue)
  // console.log(rowData.customer?.customerCode?.indexOf(filter.toUpperCase()) )
  // if (rowData.customer?.customerCode =='')
  // if (rowData.customer?.customerCode?.toUpperCase().indexOf(filter.toUpperCase()) >=0) return true;  
  // if (rowData.customer?.company?.toUpperCase().indexOf(filter.toUpperCase()) >=0) return true;  
  result = words.every((word: any) => rowData.customer?.customerCode?.toUpperCase().includes(word));
  // console.log("myfiler",filterValue,rowData.customer?.customerCode);
  
  if (result) return true;
  result = words.every((word: any) => rowData.customer?.company?.toUpperCase().includes(word));
  if (result) return true;  
  return false;
}  

const colInfos: any = {
  productInfo: {
    title: 'Product Info',
    field: 'productInfo',
    editable: false,      
    keywords: [
      colKey.productName,
      colKey.customerId,
      colKey.baseProductId,
      colKey.productCode,
    ],
    customFilterAndSearch:(
      filterValue:any,
      rowData:any) =>myfiler(
      filterValue,
      rowData
    )     
  },
  properties: {
    title: 'Properties',
    field: 'properties',
    keywords: [
      colKey.plateTypeId,
      colKey.palletStacking,
      colKey.description,
      colKey.inStock, 
    ]
  },
  quantityInfo: {
    title: 'Quantity Info',
    field: 'quantityInfo',
    keywords: [
      colKey.marginOfError,
      colKey.minOrderQuantity,
      colKey.productMsl,
      colKey.semiMsl,
    ]
  },
  productImage: {
    title: 'Image',
    field: 'images',
    keywords: [
      colKey.productImage
    ]
  },
  logoUrl: {
    title: 'Logo File',
    field: 'logoUrl',
    keywords: [
      colKey.logoUrl,
    ]
  },
  // showImgUrl: {
  //   title: 'Customer Picture',
  //   field: 'showImgUrl',
  //   keywords: [
  //     colKey.showImgUrl
  //   ]
  // }  
}
const isCellEditable = (cellData:any,rowData:any) => {
  if (!rowData) return true;  
  if (rowData.manufactured === 1) {
    return false;
  }
  return true;
};
const ProductManagementColumnModel = (): any => {
  let modelArr: any = [
    colInfos.properties,
    colInfos.quantityInfo
  ]

  modelArr = modelArr.map((row: any) => getColModelItem(row, keyInfosArray))
  
  const productInfoColumn = getColModelItem(colInfos.productInfo, keyInfosArray)
  productInfoColumn.customFilterAndSearch = colInfos.productInfo.customFilterAndSearch;
  modelArr.unshift(productInfoColumn)
  modelArr.map((row: any) => { row.editable = isCellEditable;})   
  modelArr.push({
    ...getColModelItem(colInfos.productImage, keyInfosArray),
    filtering: false,
  })
  // modelArr.push({
  //   ...getColModelItem(colInfos.showImgUrl, keyInfosArray),
  //   filtering: false,
  // })  
  modelArr.push({
    title: 'Logo Type',
    field: colKey.logoType,
    initialEditValue: 0,
    type: 'numeric',
    lookup: {0: 'Normal', 1: 'With Customer Logo', 2: 'Without Jadcup Logo', 3: 'Without made in NZ'}
  })
  modelArr.push({
    ...getColModelItem(colInfos.logoUrl, keyInfosArray),
    filtering: false,
  })
  modelArr.push({
    title: 'Plain',
    field: colKey.plain,
    initialEditValue: 0,
    type: 'numeric',
    editable: 'onAdd',
    lookup: {0: 'No', 1: 'Yes'}
  })

  return modelArr
}

export const OutsourceProductManagementColumnModel = () => {
  return [
    {
      title: 'Product Description',
      field: 'productName',
      render: (rowData: any) => rowData.productName,
      customFilterAndSearch:(
        filterValue:any,
        rowData:any) =>myfiler(
        filterValue,
        rowData
      )  
    },
    {
      title: 'Product Code',
      field: 'productCode',
      render: (rowData: any) => rowData.productCode,
      customFilterAndSearch:(
        filterValue:any,
        rowData:any) =>myfiler(
        filterValue,
        rowData
      )       
    },
    {
      title: 'Product Type',
      field: 'productType',
      render: (rowData: any) => rowData.baseProduct?.productType?.productTypeName
    },
    {
      title: 'Packaging Type',
      field: 'packagingType',
      render: (rowData: any) => rowData.baseProduct.packagingType.packagingTypeName
    },        
    {
      title: 'Base/Outsourced Product Note',
      field: 'description',
      render: (rowData: any) => rowData.description
    },
    {
      title: 'Product Specification (Will show on Quote)',
      field: 'description',
      render: (rowData: any) => rowData.baseProduct.rawmaterialDesc
    },    
  ]
}

export default ProductManagementColumnModel
