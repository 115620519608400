import React, {useState} from "react";
import {Popconfirm, Space, Table, Tabs} from "antd";
import { updateStockQuantityRequest} from "../../../../services/others/warehouse-management-services";
import SweetAlertService from "../../../../services/lib/utils/sweet-alert-service";
import {data} from "../../../../mock/dashboard-mock";
import moment from "moment";
import { ApiRequest } from "../../../../services/api/api";
import { urlType } from "../../../../services/api/api-urls";
import { getUserId } from "../../../../services/lib/utils/auth.utils";

const { TabPane } = Tabs;
const ProductTable = (props:{data:any, productData:any, productDelete:(data:any)=>void}) =>{
    const [dataToTable, setDataToTable] = useState(props.data);
    const [activeTab, setActiveTab] = useState("all"); // Default active tab

    const filteredData = activeTab === "all" ? dataToTable : dataToTable.filter((item: any) => item.palletNo === activeTab);
    console.log(props.data)
    // const pallets = Array.from(new Set(dataToTable.map((item: any) => item.palletNo)));
// Define the type of the pallets array as string[]
    const pallets: string[] = Array.from(new Set(dataToTable.map((item: any) => item.palletNo)));


    const columns:any =[
        {
            title: 'Product Name',
            dataIndex: ['product', 'productName'],
            key: 'productName',
        },
        {
            title: 'BarCode',
            dataIndex: 'barCode',
            key: 'barCode',
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Location',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Pallet',
            dataIndex: 'palletNo',
            key: 'palletNo',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (rowData: any) =>{
                // return moment.utc( rowData).local().format('DD/MM/YYYY')
                return moment(rowData).format('DD/MM/YYYY')
            },
        },
        {
            title: 'Notes',
            // dataIndex: ['product','description'],
            // key: 'description',
            dataIndex: 'notes',
            key: 'notes'          
        },
        {
            title: 'Action',
            key: 'action',
            render: (text:any, record:any) => {
                // console.log(text,record)
                return (
                    <Space size="middle">
                        <div onClick={() =>quantityChangeHandler(text.boxId)}><a>change Quantity </a></div>
                        <Popconfirm title="Sure to delete?" onConfirm={() => deleteHandler(text.boxId)}>
                            <a>Delete</a>
                        </Popconfirm>
                    </Space>
                )
            },
        },
    ]

    const handleTabChange = (key: string) => {
        setActiveTab(key);
    };

    const quantityChangeHandler = async (boxId:string) =>{
        console.log(boxId)
        const result = await SweetAlertService.inputConfirm({type:'number', title:'Update Quantity', placeholder:'qty'})
        if(result !== null){
            if(!result){
                await SweetAlertService.errorMessage('Please type in quantity before submitting')
                return
            }else{
                console.log(typeof result,'true')
                updateStockQuantityRequest(boxId, result).then(_ => {
                    const array = [...dataToTable]
                    const index = array.findIndex((res:any) => res.boxId === boxId)
                    // console.log(index)
                    array[index].quantity = parseInt(result)
                    // console.log(array)
                    setDataToTable(array)
                })
            }
        }
    }

    const deleteHandler = async (boxId:string) =>{
        const resultInput = await SweetAlertService.deleteConfirm();
        if (resultInput.dismissed) return;
        const [name, reason] = Array.isArray(resultInput.value) ? resultInput.value : [];
        console.log(`Name: ${name}, Reason: ${reason}`);

        // console.log(props.productData)
        const newData = dataToTable.filter((res:any) => res.boxId !== boxId)
        setDataToTable(newData)
        // await deleteObsoleteBox(boxId).then((res:any)=>console.log(res))
        await ApiRequest({
            url: 'Box/DeleteBox?id=' + boxId+"&Notes="+reason+"&empNotes="+name,
            method: urlType.Delete,
            isShowSpinner: true
          })           

        // console.log(newData)
        props.productDelete(newData)

    }

    if (pallets?.length === 1) {
        // If there's only one pallet, directly render the Table without Tabs
        return (
            <div>
                <Table columns={columns} dataSource={dataToTable} />
            </div>
        );
    }
    
    // If there are multiple pallets, render Tabs
    return (
        <div>
            <Tabs onChange={handleTabChange}>
                {pallets.map(pallet => (
                    <TabPane tab={`Pallet ${pallet}`} key={pallet}>
                        <Table columns={columns} dataSource={dataToTable.filter((item: any) => item.palletNo === pallet)} />
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
}

export default ProductTable
