import React, { useState,useEffect } from 'react'
import CommonTablePage from '../../../../components/common/common-table/common-table-page'
import { urlKey, urlType } from '../../../../services/api/api-urls'
import { ApiRequest } from '../../../../services/api/api'
import { toLocalDateTime } from '../../../../services/lib/utils/helpers'
import SweetAlertService from '../../../../services/lib/utils/sweet-alert-service'
import { BoxManagementColumnModel } from '../box-management-page/box-management-column-model'
import { Button } from 'antd'
import  PdfStickService from '../../../../services/others/pdf-sticker-services'
import {JadCupImg, emptyImg} from '../../../../services/others/assets'
import { getUserId } from '../../../../services/lib/utils/auth.utils'
import moment from 'moment'

const HoldProductListPage : React.FC<any> = (props) => {
  const [triggerResetData, setTriggerResetData] = useState(false)
  const [selectedRows, setSelectedRows] = useState<any>()

  useEffect(() => {
    document.title = "Hold product list";
   }, [])
  const getRenderData = (data: any) => {
    console.log(data)
const renderData: any = []
    data.map((row: any) => {
      renderData.push({
        ...row,
        productName: row.product?.productName,
        createdAt1: row.createdAt,
        createdAt: toLocalDateTime(row.createdAt),
        // position: row.position,
        position: "Holding Area",
        palletCode: row.palletCode,
      })
    })
    return renderData
  }


  const onSelectionChange = (rows: any) => {
    console.log(rows)
    setSelectedRows(rows)
  }

  const actionButtons: any = [
    {
      icon: 'RestoreIcon',
      tooltip: 'Release Back to Normal Stock',
      isFreeAction: false,
      onClick: async (event: any, rowData: any) => {
        const ids = selectedRows.map((row: any) => row.boxId)
        const resultInput = await SweetAlertService.confirmMessage();
        if (!resultInput) return;

        ApiRequest({
          url: "Box/UnMarkDefects",
          method: 'Put',
          data: ids
        }).then(_ => {
          setTriggerResetData(!triggerResetData)
        })
      }
    },
    {
      icon: 'danger',
      tooltip: 'Reject to Deadstock',
      isFreeAction: false,
      onClick: async (event: any, rowData: any) => {
        const ids = selectedRows.map((row: any) => row.boxId)
        const resultInput = await SweetAlertService.confirmMessage();
        if (!resultInput) return;

        ApiRequest({
          url: "Box/DeleteToDead",
          method: 'Put',
          data: ids
        }).then(_ => {
          setTriggerResetData(!triggerResetData)
        })
      }
    }
  ]

  return (
    <div>
      <CommonTablePage
        // urlInfoKey={urlKey.Box}
        getAllUrl = "Box/GetUnholding"
        title="Hold Product List"
        isNotAddable={true}
        isNotEditable={true}
        isNotDeletable={true}
        actionButtons={actionButtons}
        // mappingUpdateData={(dataDetail: any, type: any) => getUpdateData(dataDetail, type)}
        mappingRenderData={(data: any) => getRenderData(data)}
        column={[
            ...BoxManagementColumnModel().filter((e: any) => e.title !== "RM PO Number"),
            {
              title: 'Holded At',
              align: 'left',
              field: 'updatedAt',
              render: (rowData: any) =>moment.utc( toLocalDateTime(rowData.updatedAt)).local().format('DD/MM/YYYY'),
            }
          ]}
        triggerResetData={triggerResetData}
        isEnableSelect={true}
        onSelectionChange={onSelectionChange}
      />
    </div>
  )
}

export default HoldProductListPage
