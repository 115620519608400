import React, { useState, useEffect } from 'react';
import { Form, Input, DatePicker, Select, Button, Table, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { GetLeaveRequestDto, AddLeaveRequestDto, UpdateLeaveRequestDto } from '../../../services/model/LeaveRequestModel';
import { leaveRequestService } from '../../../services/others/leave-request-services';
import { getUserId,getName } from '../../../services/lib/utils/auth.utils';
import moment from 'moment';
import SweetAlertService from '../../../services/lib/utils/sweet-alert-service';
import { CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { sendEmail } from '../../../services/others/quotation-email-services';


const { RangePicker } = DatePicker;
const { TextArea } = Input;

const LeaveRequestPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [leaveRequests, setLeaveRequests] = useState<GetLeaveRequestDto[]>([]);
  const [leaveTypes, setLeaveTypes] = useState<{ value: number; label: string }[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    document.title = "Leave Request Management";
    fetchLeaveRequests();
    fetchLeaveTypes();
  }, []);

  const fetchLeaveRequests = async () => {
    try {
      const response = await leaveRequestService.getByEmployeeId(getUserId());
      if (response.data.success) {
        setLeaveRequests(response.data.data);
      }
    } catch (error) {
      message.error('Failed to fetch leave requests');
    }
  };

  const fetchLeaveTypes = async () => {
    try {
      const response = await leaveRequestService.getLeaveTypes();
      if (response.data.success) {
        setLeaveTypes(response.data.data.map((item: any) => ({
          value: item.leaveTypeId,
          label: item.typeName,
        })));
      } else {
        message.error('Failed to fetch leave types');
      }
    } catch (error) {
      message.error('Failed to fetch leave types');
    }
  };
  /**
   * Sets the form values to the selected leave request's data.
   * @param id The id of the leave request to edit.
   */

  const handleNewRequest = () => {
    setIsEditing(false);
    form.resetFields();
    setSelectedLeaveRequestId('')
  };

  const [selectedLeaveRequestId, setSelectedLeaveRequestId] = useState<string>('');
  const handleEdit = (id: string) => {
    setIsEditing(true);
    const record = leaveRequests.find((item) => item.leaveRequestId === id);
    if (record) {
      form.setFieldsValue({
        leaveType: record.leaveTypeId,
        dates: [
            moment.utc(record.startDate).local(), // 转换为本地时间
            moment.utc(record.endDate).local(),   // 转换为本地时间
          ],
        reason: record.leaveReason,
      });
      setSelectedLeaveRequestId(id);
    }
  };

  const handleDelete = async (id: string) => {
    const res = await SweetAlertService.confirmMessage('Are you sure you want to delete this leave request?')
    if (!res) return;
    try {
      const response = await leaveRequestService.delete(id);
      if (response.data.success) {
        // message.success('Leave request deleted successfully');
        fetchLeaveRequests();
      }
    } catch (error) {
      message.error('Failed to delete leave request');
    }
  };
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const [startDate, endDate] = values.dates;
      

      const baseRequest = {
        employeeId: getUserId(),
        leaveTypeId: values.leaveType,
        startDate: startDate.format('YYYY-MM-DD'), // 转换为 UTC 日期字符串
        endDate: endDate.format('YYYY-MM-DD'),     // 转换为 UTC 日期字符串
        leaveReason: values.reason,
      };
      let response;
      if (isEditing && selectedLeaveRequestId) {
        // For update operation, include leaveRequestId
        const updateRequest: UpdateLeaveRequestDto = {
          ...baseRequest,
          leaveRequestId: selectedLeaveRequestId, // Add leaveRequestId for update
        };
        response = await leaveRequestService.update(updateRequest);
      } else {
        // For add operation, use the base request
        const addRequest: AddLeaveRequestDto = baseRequest;
        response = await leaveRequestService.add(addRequest);
      }

      if (response.data.success) {
        // message.success(isEditing ? 'Leave request updated successfully' : 'Leave request submitted successfully');
        if (!isEditing) {
          sendEmailToManager(baseRequest);
        }
        form.resetFields();
        fetchLeaveRequests();
      }
    } catch (error) {
      message.error('Failed to submit leave request');
    } finally {
      setLoading(false);
    }
  };
  
  
  const sendEmailToManager = async (rawData: any, ) => {
    const senderAddress = "Info@jadcup.co.nz";
    let targetAddress: string | Blob = "ken@jadcup.co.nz";
    const title =  "Attention: Leave Request";
    const leaveTypeName = leaveTypes.find((item) => item.value === rawData.leaveTypeId)?.label || 'Unknown';
    const leaveDuration = `${moment(rawData.startDate).format('DD/MM/YYYY')} to ${moment(rawData.endDate).format('DD/MM/YYYY')}`;
    
    let HtmlContent = `
    <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
          }
          .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            background-color: #ffffff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          h1 {
            color: #333;
          }
          p {
            color: #555;
            line-height: 1.4;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <h1>Leave Request: ${getName()}</h1>
          <p><strong>Name: </strong> ${getName()}</p>
          <p><strong>Leave Type: </strong> ${leaveTypeName}</p>
          <p><strong>Duration: </strong> ${moment(rawData.startDate).format('DD/MM/YYYY')} to ${moment(rawData.endDate).format('DD/MM/YYYY')}</p>
          <p><strong>Reason: </strong> ${rawData.leaveReason}</p>
        </div>
      </body>
    </html>
    `;
    
    // targetAddress = await SweetAlertService.inputConfirm({type: 'text', title: 'Please confirm target email', placeholder: '', defaultValue: "",handleRequired:true});
    // if (!targetAddress) return
    const formData = new FormData();
    formData.append('SenderName', 'Jadcup Admin');
    formData.append('SenderAddress', senderAddress);
    formData.append('TargetAddress', targetAddress);
    formData.append('Subject', title);
    formData.append('HtmlContent', HtmlContent);
    // return
    return sendEmail(formData)
      .then((res) => {
        if (res.data.isSuccessStatusCode === false) {
          SweetAlertService.errorMessage("Send email fail!");
        }
      });
  };
  const columns: ColumnsType<GetLeaveRequestDto> = [
    // {
    //   title: 'Leave Request ID',
    //   dataIndex: 'id',
    //   key: 'leaveRequestId',
    //   render: () => null, // Hidden column
    // },
    {
      title: 'Leave Type',
      dataIndex: ['leaveType', 'typeName'],
      key: 'leaveType',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: Date | null) => date ? new Date(date).toLocaleDateString('en-GB') : 'N/A',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date: Date | null) => date ? new Date(date).toLocaleDateString('en-GB') : 'N/A',
    },
    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      key: 'status',
    },
    {
      title: 'Reason',
      dataIndex: 'leaveReason',
      key: 'reason',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: Date | null) => date ? new Date(date).toLocaleDateString('en-GB') : 'N/A',
    },
    {
      title: 'Approval Status',
      dataIndex: 'approvalStatus',
      key: 'approvalStatus',
      render: (status: string) => (
        <span>
          {status === 'Pending Approval' ? <MinusCircleOutlined style={{ color: '#faad14' }} /> :
            status === 'Rejected' ? <CloseCircleOutlined style={{ color: '#ff4d4f' }} /> :
            <CheckCircleOutlined style={{ color: '#52c41a' }} />}
          {status}
        </span>
      ),
    },
    {
      title: 'Approval Comment',
      dataIndex: 'approvalComment',
      key: 'approvalComment',
    },
    {
      title: 'Approved At',
      dataIndex: 'approvedAt',
      key: 'approvedAt',
      render: (date: Date | null) => date ? new Date(date).toLocaleDateString('en-GB') : 'N/A',
    }
  ];

  return (
    <div>
      {/* <h1>Leave Request</h1> */}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{ maxWidth: 600, marginBottom: 24 }}
      >
        <Form.Item
          label="Leave Type"
          name="leaveType"
          rules={[{ required: true, message: 'Please select leave type' }]}
        >
          <Select options={leaveTypes} placeholder="Select leave type" />
        </Form.Item>

        <Form.Item
          label="Dates"
          name="dates"
          rules={[{ required: true, message: 'Please select date range' }]}
        >
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Reason"
          name="reason"
          rules={[{ required: true, message: 'Please enter reason' }]}
        >
          <TextArea rows={4} placeholder="Enter reason for leave" />
        </Form.Item>

        <Form.Item style={{ marginTop: 16 }}>
          <Button
            type="default" 
            onClick={handleNewRequest}
            style={{ marginRight: 40 }}
          >
            New Leave Request
          </Button>
          <Button type="primary"htmlType="submit" loading={loading}>
            Save Leave Request
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={[
          ...columns,
          {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
              <span>
                {record.approvalStatus === 'Pending Approval' && (
                  <>
                    <Button type="link" onClick={() => handleEdit(record.leaveRequestId)}>Edit</Button>
                    <Button type="link" onClick={() => handleDelete(record.leaveRequestId)}>Delete</Button>
                  </>
                )}
              </span>
            ),
          },
        ]}
        dataSource={leaveRequests}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default LeaveRequestPage;

