import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Input, DatePicker, Switch, Table, Card, Select } from 'antd';
import { ApiRequest } from '../../../services/api/api';
import moment from 'moment'; // 添加这一行
import { CheckOutlined, CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { getUserId } from '../../../services/lib/utils/auth.utils';
import SweetAlertService from '../../../services/lib/utils/sweet-alert-service';
import './weekly-report-dialog.css'
import Typography from 'antd/lib/typography/Typography';
// import './quotation-management-dialog.css'
const WEEKEND_WORK_TYPE_ID = 92;
const TOBE_FILLED_WORK_TYPE_ID = 93;

const WeeklyReportDialog = ({ visible, onCancel, onSubmit, reportDate, selectedReport, role }: any) => {
  const initialReportData = {
    weeklyReportId: null, // 使用默认的报告ID
    employeeId: 0, // 使用默认的员工ID
    reportDate: reportDate,
    content: '', // 使用默认的报告内容
    approvalStatus: "0", // 使用默认的报告状态
    active: true,
    dailyReport: Array(7).fill({
      reportDate: '',
      content: '',
      leaveType: null,
      leaveHours: null,
      dailyReportWorkHours: [{ workTypeId: TOBE_FILLED_WORK_TYPE_ID, workHour: 8 }],
      image: null // 添加 image 字段
    }).map((report, index) => {
      if (index >= 5) { // 最后2条是周末
        return {
          ...report,
          dailyReportWorkHours: [{ workTypeId: WEEKEND_WORK_TYPE_ID, workHour: 0 }] // workhour ：0
        };
      }
      return report;
    })
  };

  const [reportData, setReportData] = useState(initialReportData);
  const [workTypesOptions, setWorkTypesOptions] = useState([]); 
  const [leaveTypesOptions, setLeaveTypesOptions] = useState([]);
  const [totalWorkHours, setTotalWorkHours] = useState(0);
  const [totalLeaveHours, setTotalLeaveHours] = useState(0);
  const [totalTOBE_FILLEDHours, setTotalTOBE_FILLEDHours] = useState(0);
  const [templateReports, setTemplateReports] = useState<any[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');

  useEffect(() => {
    if (selectedReport) {
      setReportData({
        weeklyReportId: selectedReport.weeklyReportId || null,
        employeeId: selectedReport.employeeId || 0,
        reportDate: reportDate,
        content: selectedReport.content || '',
        approvalStatus: selectedReport.approvalStatus || "0",
        active: true,
        dailyReport: Array(7).fill({
          reportDate: '',
          content: '',
          leaveTypeId: null,
          leaveHours: null,
          dailyReportWorkHours: [{ workTypeId: null, workHour: 8 }],
          leaveUrl: null
        }).map((report, index) => {
          const selectedDailyReport = selectedReport.dailyReport[index] || {};
          return {
            ...report,
            dailyReportId: selectedDailyReport.dailyReportId,
            weeklyReportId: selectedDailyReport.weeklyReportId,
            reportDate: selectedDailyReport.reportDate ? moment(selectedDailyReport.reportDate).format('YYYY-MM-DD') : '',
            content: selectedDailyReport.content || '',
            leaveTypeId: selectedDailyReport.leaveTypeId || null,
            leaveHours: selectedDailyReport.leaveHours || null,
            dailyReportWorkHours: selectedDailyReport.dailyReportWorkHours || [{ workTypeId: null, workHour: 8 }],
            leaveUrl: selectedDailyReport.leaveUrl || null
          };
        }).sort((a, b) => new Date(a.reportDate).getTime() - new Date(b.reportDate).getTime())
      });
    } else {
      const updatedDailyReport = initialReportData.dailyReport.map((report, index) => ({
        ...report,
        reportDate: reportDate.clone().add(index, 'days').format('YYYY-MM-DD')
      }));
      setReportData({ ...initialReportData, dailyReport: updatedDailyReport });
    }
    setSelectedTemplateId('');
    fetchTemplates();
  }, [reportDate,selectedReport]); // 添加 selectedReport 作为依赖项
  const fetchTemplates = async () => {
    try {
      // debugger
      const response = await ApiRequest({
        url: `/weeklyReportTemplate/employer?employerId=${getUserId()}`,
        method: 'get',
      });
      console.log('Templates loaded for employer:', response);
      if (response.data.success) {
        const templates = response.data.data.map((template: any) => ({
          weeklyReportId: template.weeklyReportId,
          weeklyReportName: template.templateName,
          weeklyReport: template.weeklyReport
        }));
        setTemplateReports(templates);
        console.log('Templates loaded:', templates);
      } else {
        console.error('Error loading templates:', response.data.errorMessage.message);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };  
  const calculateTotalHours = () => {
    let totalWorkHours = 0;
    let totalLeaveHours = 0;
    let totalTOBE_FILLEDHours = 0;
    reportData.dailyReport.forEach(report => {
      report.dailyReportWorkHours.forEach((work: { workTypeId: number; workHour: number; }) => {
        if (work.workTypeId === WEEKEND_WORK_TYPE_ID) {
          return;
        }
        if (work.workTypeId === TOBE_FILLED_WORK_TYPE_ID) {
          totalTOBE_FILLEDHours += work.workHour;
        } else {
          totalWorkHours += work.workHour;
        }
      });
    });
    reportData.dailyReport.forEach(report => {
      if (report.leaveHours) {
        totalLeaveHours += report.leaveHours;
      }
    });
    console.log('totalWorkHours:', totalWorkHours);
    console.log('totalLeaveHours:', totalLeaveHours);
    console.log('totalTOBE_FILLEDHours:', totalTOBE_FILLEDHours);
    setTotalWorkHours(totalWorkHours);
    setTotalLeaveHours(totalLeaveHours);
    setTotalTOBE_FILLEDHours(totalTOBE_FILLEDHours);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, workIndex: number) => {
    const { name, value } = e.target;
    const newDailyReport = [...reportData.dailyReport];
  
    if (name === 'leaveHours' || name === 'content') {
      newDailyReport[index] = { ...newDailyReport[index], [name]: name === 'leaveHours' ? Number(value) : value };
    } else {
      const newDailyReportWorkHours = [...newDailyReport[index].dailyReportWorkHours];
      newDailyReportWorkHours[workIndex] = { ...newDailyReportWorkHours[workIndex], [name]: name === 'workHour' ? Number(value) : value };
      newDailyReport[index] = { ...newDailyReport[index], dailyReportWorkHours: newDailyReportWorkHours };
    }
  
    setReportData({ ...reportData, dailyReport: newDailyReport });
  };

  const hasTobeFilledWorkTypeId = (dailyReport: any[]) => {
    const result = dailyReport.some((report, j) => {
      return report.dailyReportWorkHours.some((work: { workTypeId: string | null | undefined; }) => 
        work.workTypeId == TOBE_FILLED_WORK_TYPE_ID.toString()
      );
    });
    return result;
  }

  const handleDateChange = (date: any, index: number) => {
    const newDailyReport = [...reportData.dailyReport];
    newDailyReport[index] = { ...newDailyReport[index], reportDate: date.toISOString().split('T')[0] };
    setReportData({ ...reportData, dailyReport: newDailyReport });
  };
  const formatDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  };
  
  const handleDraft = () => {
    reportData.employeeId = getUserId();
    const isValid = reportData.dailyReport.every(report => 
      report.dailyReportWorkHours.every((work: { workTypeId: null | undefined; workHour: number; }) => 
        work.workTypeId !== null && work.workTypeId !== undefined && work.workHour >= 0
      )
    );
    if (!isValid) {
      SweetAlertService.errorMessage("Please ensure all work types and work hours are correctly filled out.")
      return;
    }
    reportData.reportDate = formatDate(reportData.reportDate)
    reportData.approvalStatus = "-2"; //Draft
    const requestData = selectedReport ? {
      url: 'WeeklyReport/Update',
      method: 'put',
      data: reportData
    } : {
      url: 'WeeklyReport/Add',
      method: 'post',
      data: reportData
    };
    ApiRequest(requestData).then(response => {
      console.log('Report submitted successfully', response);
      onSubmit();
    }).catch(error => {
      console.error('Error submitting report', error);
    });
  };

  function isReportSubmissionTimeValid_until_monday(reportData:any) {
    const currentDate = new Date();
    const reportDateObj = new Date(reportData.reportDate);
    
    // 确保报告日期是周一
    if (reportDateObj.getDay() !== 1) {
        return false;
    }
    
    // 计算报告周的周五和下周二的日期
    const reportWeekFriday = new Date(reportDateObj);
    reportWeekFriday.setDate(reportDateObj.getDate() + 4);
    reportWeekFriday.setHours(16, 0, 0, 0); // 周五下午4点
    
    const reportWeekNextTuesday = new Date(reportDateObj);
    reportWeekNextTuesday.setDate(reportDateObj.getDate() + 8);
    reportWeekNextTuesday.setHours(0, 0, 0, 0); // 下周二凌晨
    
    // 周五需要4点以后，其他天直接比较
    const currentDayOfWeek = currentDate.getDay();
    const isValidSubmissionTime = 
        (currentDayOfWeek === 5 
            ? currentDate >= reportWeekFriday 
            : currentDate >= reportWeekFriday && currentDate < reportWeekNextTuesday);
    
    return isValidSubmissionTime;
}

function isReportSubmissionTimeValid(reportData: any): boolean {
  const currentDate = new Date();
  const reportDateObj = new Date(reportData.reportDate);
  
  // 确保报告日期是周一
  if (reportDateObj.getDay() !== 1) {
      return false;
  }
  
  // 计算报告周的周五下午4点
  const reportWeekFriday = new Date(reportDateObj);
  reportWeekFriday.setDate(reportDateObj.getDate() + 4); // 本周五
  reportWeekFriday.setHours(12, 0, 0, 0); // 周五中午12点
  
  // 计算第二周的结束时间（下下周的周日午夜）
  const secondWeekEnd = new Date(reportDateObj);
  secondWeekEnd.setDate(reportDateObj.getDate() + 14); // 第二周结束（下下周的周日）
  secondWeekEnd.setHours(23, 59, 59, 999); // 周日午夜
  const isValidSubmissionTime = 

  currentDate >= reportWeekFriday && currentDate <= secondWeekEnd;

  return isValidSubmissionTime;
}
  // const handleSubmitDraft = () => {
  //   reportData.approvalStatus = "0"; // Not approved
  //   const requestData =  {
  //     url: `WeeklyReport/Approve?reportId=${selectedReport.weeklyReportId}`,
  //     method: 'put',
  //     data: reportData
  //   } 
  //   ApiRequest(requestData).then(response => {
  //     console.log('Report submitted successfully', response);
  //     onSubmit();
  //   }).catch(error => {
  //     console.error('Error submitting report', error);
  //   });
  // };
  const handleSubmit = () => {
    reportData.employeeId = getUserId();
    const isValid = reportData.dailyReport.every(report => 
      report.dailyReportWorkHours.every((work: { workTypeId: null | undefined; workHour: number; }) => 
        work.workTypeId !== null && work.workTypeId !== undefined && work.workHour >= 0
      )
    );
    if (!isValid || totalTOBE_FILLEDHours > 0) {
      SweetAlertService.errorMessage("Please ensure all work types and work hours are correctly filled out and there are no Tobe_Filled hours.")
      return;
    }
    if (!isReportSubmissionTimeValid(reportData)) {
      // SweetAlertService.errorMessage("Submit time must be from Friday 4 PM to Monday.")
      SweetAlertService.errorMessage("Submission time must be from Friday to within the following week.");
      return;
    }    
    reportData.reportDate = formatDate(reportData.reportDate)
    reportData.approvalStatus = "0"; // Not approved
    const requestData = selectedReport ? {
      url: 'WeeklyReport/Update',
      method: 'put',
      data: reportData
    } : {
      url: 'WeeklyReport/Add',
      method: 'post',
      data: reportData
    };
    ApiRequest(requestData).then(response => {
      console.log('Report submitted successfully', response);
      onSubmit();
    }).catch(error => {
      console.error('Error submitting report', error);
    });
  };
  const handleApproveOrReject = async (action: 'approve' | 'reject') => {
    let requestData;
    if (action === 'reject') {
      const reason = await SweetAlertService.inputConfirm({
        type: 'textarea',
        title: 'Reject Report',
        textLabel: 'Reason for Rejection',
        placeholder: 'Please provide a reason for rejecting the report',
        handleRequired: true
      });
      if (!reason) return;
      requestData = {
        url: `WeeklyReport/Reject?reportId=${selectedReport.weeklyReportId}&reason=${encodeURIComponent(reason)}`,
        method: 'put'
      };
    } else {
      requestData = {
        url: `WeeklyReport/Approve?reportId=${selectedReport.weeklyReportId}`,
        method: 'put'
      };
    }

    ApiRequest(requestData).then(response => {
      console.log(`Report ${action}d successfully`, response);
      onSubmit();
    }).catch(error => {
      console.error(`Error ${action}ing report`, error);
    });
  };

  const isReportSaved = () => {
    if (selectedReport) {
      return {
        isSaved: true,
        reportId: selectedReport.weeklyReportId
      };
    }
    return {
      isSaved: false,
      reportId: 0
    };
  };
  const handleSaveAsTemplate = async () => {
    // handleSubmit()
    // debugger
    if (!isReportSaved().isSaved) {
      SweetAlertService.errorMessage('Cannot save an unsaved report as a template', 'Error');
      return;
    }
    
    const result = await SweetAlertService.inputConfirm({
      type: 'text',
      title: 'Save as Template',
      defaultValue: '',
      placeholder: 'Please provide a name for the template'
    });

    const templateName = result;
    if (!templateName) return;
    const requestData = {
      url: '/WeeklyReportTemplate',
      method: 'post',
      data: { content:"default content",templateName, weeklyReportId: isReportSaved().reportId }
    };
    ApiRequest(requestData).then(response => {
      console.log('Template saved successfully', response);
    }).catch(error => {
      console.error('Error saving template', error);
    });
  };
  const addWorkType = (index: number) => {
    setReportData(prevData => {
      const newDailyReport = [...prevData.dailyReport];
      const newDailyReportWorkHours = [...newDailyReport[index].dailyReportWorkHours, { workTypeId: null, workHour: 0 }];
      newDailyReport[index] = { ...newDailyReport[index], dailyReportWorkHours: newDailyReportWorkHours };
      return { ...prevData, dailyReport: newDailyReport };
    });
  };

  const removeWorkType = (index: number, workIndex: number) => {
    setReportData(prevData => {
      const newDailyReport = [...prevData.dailyReport];
      const newDailyReportWorkHours = [...newDailyReport[index].dailyReportWorkHours];
      if (newDailyReportWorkHours.length === 1) {
        SweetAlertService.errorMessage('At least one work type must be retained', 'Cannot delete the last work type');
        return prevData;
      }

      newDailyReportWorkHours.splice(workIndex, 1);
      newDailyReport[index] = { ...newDailyReport[index], dailyReportWorkHours: newDailyReportWorkHours };
      return { ...prevData, dailyReport: newDailyReport };
    });
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString().split(',')[1];
        if (base64String) {
          const newDailyReport = [...reportData.dailyReport];
          newDailyReport[index] = { ...newDailyReport[index], image: base64String };
          setReportData({ ...reportData, dailyReport: newDailyReport });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const statusName = (status: string) => {
    switch (status) {
      case '0':
        return 'Not Approved';
      case '-1':
        return 'Rejected';
      case '-2':
        return 'Draft';
      default:
        return 'Approved';
    }
  };
  const handleLeaveTypeChange = (value: string, index: number) => {
    setReportData(prevData => {
      const newDailyReport = [...prevData.dailyReport];
      newDailyReport[index] = { ...newDailyReport[index], leaveTypeId: value };
      return { ...prevData, dailyReport: newDailyReport };
    });
  };
  useEffect(() => {
    calculateTotalHours()
    // if (workTypesOptions == null || leaveTypesOptions == null) {
      Promise.all([
        ApiRequest({
          url: '/WeeklyReport/GetAllWorkTypes',
          method: 'get'
        }),
        ApiRequest({
          url: '/WeeklyReport/GetAllLeaveTypes',
          method: 'get'
        })
      ]).then(([workTypesResponse, leaveTypesResponse]) => {
        const workTypesOptionsData = workTypesResponse.data.data.map((workType: any) => ({
          value: workType.workTypeId,
          label: workType.workTypeName
        }));
        const leaveTypesOptionsData = leaveTypesResponse.data.data.map((leaveType: any) => ({
          value: leaveType.leaveTypeId,
          label: leaveType.typeName
        }));
        console.log('workTypesOptionsData:', workTypesOptionsData);
        console.log('leaveTypesOptionsData:', leaveTypesOptionsData);
        setWorkTypesOptions(workTypesOptionsData);
        setLeaveTypesOptions(leaveTypesOptionsData);
      }).catch(error => {
        console.error('Error fetching work types or leave types', error);
      });
    // }
  }, [reportData]); // 添加reportData作为依赖项
  const getWorkTypeColumns = (index: number) => [
    {
      title: 'Work Type',
      dataIndex: 'workTypeId',
      key: 'workTypeId',
      render: (text: string, record: any, workIndex: number) => {
        const handleChange = (value: string) => {
          const event = { target: { name: 'workTypeId', value } } as React.ChangeEvent<HTMLInputElement>;
          handleInputChange(event, index, workIndex);
        };
        return (
          <Select
            style={{ width: '200px' }} // 固定宽度为200px
            value={record.workTypeId}
            onChange={handleChange}
            options={workTypesOptions.length > 0 ? workTypesOptions : [{ value: '', label: 'Loading...' }]}
            placeholder="Select Work Type"
            showSearch
            filterOption={(input:any, option:any) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      }
    },
    {
      title: 'Work Hour',
      dataIndex: 'workHour',
      key: 'workHour',
      render: (text: string, record: any, workIndex: number) => (
        <Input style={{ width: '200px' }} type="number" name="workHour" value={record.workHour} onChange={(e) => handleInputChange(e, index, workIndex)} required />
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: any, workIndex: number) => (
        <div>
          <Button onClick={() => addWorkType(index)} icon={<PlusOutlined />} style={{ color: 'green' }}>Add</Button>          
          <Button onClick={() => removeWorkType(index, workIndex)} icon={<MinusOutlined />} style={{ color: 'red' }}>Remove</Button>
        </div>
      )
    }
  ];
  /**
   * Process the weekly report data by adding the report date to each daily report.
   * The report date is added as the first day of the week (Monday) and each subsequent
   * daily report is incremented by one day.
   * @param weeklyReport The weekly report data to be processed.
   * @returns A new weekly report object with the report date added to each daily report.
   */
  const processWeeklyReportData = (weeklyReport: any) => {
    const newWeeklyReport = { ...weeklyReport };
    const formattedReportDate = moment(reportDate);
    newWeeklyReport.reportDate = reportDate;
    newWeeklyReport.dailyReport = newWeeklyReport.dailyReport
      .sort((a: any, b: any) => moment(a.reportDate).diff(moment(b.reportDate))) // Replace 'someProperty' with the actual property to sort by
      .map((dailyReport: any, index: number) => {
        const dailyReportDate = formattedReportDate.clone().add(index, 'days').format('YYYY-MM-DD');
        return { ...dailyReport, reportDate: dailyReportDate };
      });
    return newWeeklyReport;
  };

  const loadTemplateReport = async (weeklyReportId: string) => {
    let result = await SweetAlertService.confirmMessage('Do you want to load this template report?')
    if (!result)  {
      return
    }
    console.log('Loading template report with ID:', weeklyReportId);
    const selectedTemplate = templateReports.find((template) => template.weeklyReportId === weeklyReportId);
    console.log('Loaded template report',selectedTemplate)
    if (selectedTemplate) {
      let newWeeklyReport = processWeeklyReportData(selectedTemplate.weeklyReport);
      setReportData(newWeeklyReport);
      setSelectedTemplateId(weeklyReportId);
    }
  };
  const handleDeleteAllTemplates = async () => {
    let result = await SweetAlertService.confirmMessage('Do you want to delete all templates?')
    if (!result)  {
      return
    }
    console.log('Deleting all templates...');
    const requestData = {
      url: `/weeklyReportTemplate/employer?employerId=${getUserId()}`,
      method: 'delete'
    };
    try {
      const response = await ApiRequest(requestData);
      console.log('Templates deleted successfully', response);
      if (response.data.success) {
        setTemplateReports([]);
      } else {
        console.error('Error deleting templates:', response.data);
        SweetAlertService.errorMessage('Error deleting templates');
      }
    } catch (error) {
      console.error('Error deleting templates:', error);
      SweetAlertService.errorMessage('Error deleting templates');
    }
  };
  return (
    <Modal
      title="Weekly Report"
      visible={visible}
      onCancel={onCancel}
      footer={[
        role !== 'manager' && role !== 'viewer' ? (
          <>
            <Button key="template" type="default" onClick={() => {
              handleSaveAsTemplate();
            }} icon={<MinusOutlined />}>
              Save as Template
            </Button>
            <Button key="draft" type="default" onClick={handleDraft} icon={<MinusOutlined />}>
              Save as Draft
            </Button>
          </>
        ) : null,
        role === 'manager' ? (
          <>
            <Button key="approve" type="primary" onClick={() => handleApproveOrReject('approve')} icon={<CheckOutlined />}>
              Approve
            </Button>
            <Button key="reject" type="default" onClick={() => handleApproveOrReject('reject')} icon={<CloseOutlined />}>
              Reject
            </Button>
          </>
        ) : role === 'viewer' ? null : (
          <>
            <Button key="submit" type="primary" onClick={handleSubmit} icon={<CheckOutlined />}>
              Submit
            </Button>          
            <Button key="back" onClick={onCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </>
        ),
      ]}
      width="80vw" // 设置宽度为屏幕的80%
    >
      <div style={{ padding: '4px', border: '1px solid #e8e8e8', borderRadius: '4px', marginBottom: '2px' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
            <label style={{ marginRight: '4px' }}>Report Date:</label>
            <DatePicker defaultValue={reportDate} onChange={(date) => handleDateChange(date, 0)} disabled value={reportDate} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
            <label style={{ marginRight: '4px' }}>Approve Notes:</label>
            <Input type="text" name="content" value={reportData.content} onChange={(e) => setReportData({ ...reportData, content: e.target.value })} readOnly style={{ backgroundColor: '#f0f0f0' }}/>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
            <label style={{ marginRight: '4px' }}>Status:</label>
            <Input type="text" name="approvalStatus" value={statusName(reportData.approvalStatus)} readOnly style={{ backgroundColor: '#f0f0f0' }} />
          </div>
          {role !== 'manager' && role !== 'viewer' && (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
              <label style={{ marginRight: '4px' }}>Load Template:</label>
              <Select
                style={{ width: '100%' }}
                placeholder="Select a template to load"
                onChange={(value) => loadTemplateReport(value)}
                value={selectedTemplateId}
              >
                {templateReports.map((template) => (
                  <Select.Option key={template.weeklyReportId} value={template.weeklyReportId}>
                    { template.weeklyReportName}
                  </Select.Option>
                ))}
              </Select>
              <Button style={{ marginLeft: '4px' }} onClick={handleDeleteAllTemplates}>Delete All Templates</Button>
            </div>
          )}
        </div>
      
        {reportData.dailyReport.map((report, index) => (
          <div key={index} style={{ marginBottom: '8px', padding: '8px', border: hasTobeFilledWorkTypeId([report]) ? '2px dashed red' : '1px solid #e8e8e8', borderRadius: '4px', backgroundColor: index < 5 ? '#e6f7ff' : '#f0f0f0' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginRight: '4px' }}>
                <label style={{ marginBottom: '2px', width: '100px' }}>{`${['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][index]}`}</label>
                {/* 调试 report.reportDate */}
                <span>{report.reportDate}</span>      
              </div>
              <Table
                  dataSource={report.dailyReportWorkHours}
                  columns={getWorkTypeColumns(index).map(col => ({
                    ...col,
                    render: (text: string, record: any, workIndex: number) => col.render(text, record, workIndex)
                  }))}
                  pagination={false}
                  rowKey={(record, workIndex) => (workIndex ?? 0).toString()}
                  style={{ width: '100%' }}
                  className="compact-table"
                />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '8px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                <label style={{ marginRight: '4px' }}>Leave Type:</label>
                <Select
                  style={{ width: '100%' }}
                  value={report.leaveTypeId}
                  onChange={(value) => handleLeaveTypeChange(value, index)}
                >
                  {leaveTypesOptions.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                <label style={{ marginRight: '4px' }}>Leave Hours:</label>
                <Input type="number" name="leaveHours" value={report.leaveHours} onChange={(e) => handleInputChange(e, index, 0)} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                <label style={{ marginRight: '4px' }}>Notes:</label>
                <Input type="text" name="content" value={report.content} onChange={(e) => handleInputChange(e, index, 0)} />
              </div>
              {(report.image || report.leaveUrl) && (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <img 
                    src={report.image ? `data:image/png;base64,${report.image}` : report.leaveUrl}                     
                    alt="Uploaded" 
                    style={{ maxWidth: '40px', maxHeight: '40px', cursor: 'pointer' }} 
                    onClick={() => {
                      const modal = document.createElement('div');
                      modal.style.position = 'fixed';
                      modal.style.top = '0';
                      modal.style.left = '0';
                      modal.style.width = '100%';
                      modal.style.height = '100%';
                      modal.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
                      modal.style.display = 'flex';
                      modal.style.justifyContent = 'center';
                      modal.style.alignItems = 'center';
                      modal.style.zIndex = '1000';

                      const img = document.createElement('img');
                      img.src = report.leaveUrl;
                      img.style.maxWidth = '80%';
                      img.style.maxHeight = '80%';
                      img.style.borderRadius = '4px';

                      modal.appendChild(img);
                      document.body.appendChild(modal);

                      modal.onclick = () => {
                        document.body.removeChild(modal);
                      };
                    }}
                  />
                </div>
              )}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                <label style={{ marginRight: '4px' }}>Upload Image:</label>
                <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, index)} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ textAlign: 'left', position: 'fixed', bottom: '0', width: '100%', backgroundColor: 'white', zIndex: '1000' }}>
        <div>
          <span style={{ color: 'blue', fontWeight: 'bold' }}>Total Work Hours: {totalWorkHours}</span>
          <span style={{ marginLeft: '20px', color: 'green', fontWeight: 'bold' }}>Total Leave Hours: {totalLeaveHours}</span>
          <span style={{ marginLeft: '20px', color: 'red', fontWeight: 'bold' }}>Total Tobe_Filled Hours: {totalTOBE_FILLEDHours}</span>
        </div>
      </div>
    </Modal>
  );
};

export default WeeklyReportDialog;