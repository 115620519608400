import React, { useState ,useEffect} from 'react'
import CommonTablePage from '../../../components/common/common-table/common-table-page'
import { urlKey, urlType } from '../../../services/api/api-urls'
import CustomerManagementColumnModel from './customer-management-column-model'
import { ApiRequest } from '../../../services/api/api'
import { baseUrl } from '../../../services/api/base-url'
import QuotationManagementPage from '../quotation-management-page/quotation-management-page'
import CommonDialog from '../../../components/common/others/common-dialog'
import SalesOrderManagementPage from '../../order/sales-order/sales-order-management-page'
import ExtraAddressManagementPage from './extra-address-management-page/extra-address-management-page'
import ContactManagementPage from './contact-management-page/contact-management-page'
import {getRandomKey} from '../../../services/lib/utils/helpers'
import SweetAlertService from '../../../services/lib/utils/sweet-alert-service'
import CustomerFilePage from './customer-file-page/customer-file-page'
import { ContactsOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import './customer-management.css'
import { sendEmail } from '../../../services/others/quotation-email-services'
import moment from 'moment'
import Swal from 'sweetalert2'


const CustomerManagementPage = (props: {salesId?: any, salesView?: boolean}): any => {
  const [triggerResetData, setTriggerResetData] = useState<any>(false)
  const [open, setOpen] = useState(false)
  const [dialogContent, setDialogContent] = useState<any>()
  const [dialogTitle, setDialogTitle] = useState<any>()
  let isCreate = false

  useEffect(() => {
    if (props.salesId) document.title = "Customer Management"; 
    else if (props.salesView) document.title = "Customer";
    else document.title = "Customer Admin Management";
  }, [])

  const resetData = () => {
    setTriggerResetData(getRandomKey())
  }

  const attachmentManagementDialog = (customerId: any) => <CustomerFilePage customerId={customerId}  />

  const quotationManagementDialog = (customerId: any) => <QuotationManagementPage customerId={customerId} />

  const orderManagementDialog = (customerId: any) => <SalesOrderManagementPage customerId={customerId} />

  const extraAddrDialog = (customerId: any) => <ExtraAddressManagementPage customerId={customerId} isReadOnly={props.salesView} />

  const contactDialog = (customerId: any) => <ContactManagementPage customerId={customerId} isReadOnly={props.salesView} />

  const sendEmailToSales = (rawData:any,targetAddress:string,resultNote:string,status:number ) => {
    // const targetAddress = rawData.employee.email;
    const senderAddress = "Edwin@gradspace.org";
    const title = (status == 1)
      ? "Attention: Customer's account is Activated" :"Attention: Customer's account is Deactivated/Locked";
    const customerName = rawData.customerCode+"/"+rawData.company; 
    const profileNote = resultNote; 
    const deactivationTime = moment().format('DD/MM/YYYY');
    
    let HtmlContent = `
    <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
          }
          .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            background-color: #ffffff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          h1 {
            color: #333;
          }
          p {
            color: #555;
            line-height: 1.4;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <h1>Customer Account Deactivated/Locked</h1>
          <p>Please contact the accountant for more details and follow-up.</p>
          <p><strong>Customer Info: </strong> ${customerName}</p>
          <p><strong>Profile Note: </strong> ${profileNote}</p>
          <p><strong>Time Deactivated/Locked: </strong> ${deactivationTime}</p>
        </div>
      </body>
    </html>
    `;
    if (status == 1) {
      HtmlContent = HtmlContent.replaceAll("Deactivated/Locked", "Activated");
    }

    const formData = new FormData();
    formData.append('SenderName', 'Jadcup Admin');
    formData.append('SenderAddress',senderAddress);
    formData.append('TargetAddress', targetAddress);
    formData.append('Subject', title);
    formData.append('HtmlContent', HtmlContent);

    return sendEmail(formData)
      .then((res) => {
        if (res.data.isSuccessStatusCode === false) {
          SweetAlertService.errorMessage("Send email fail!");
        }
      });
  };
  const isValidEmail = (email:string) => {
    // Regular expression for basic email validation
    if (!email) return false;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }
  const updateCustomerStatus = async (rowData: any, statusId: any) => {
        const result = await Swal.fire({
        title: `${statusId === 1?"Activate":"Deactivate"}`,
        html: `
        <div class="swal-input-container">
          <label for="Note" style="color: Black; font-weight: bold;">Profile Note for ${statusId === 1 ? "Activate": "Deactivate"}</label>
        </div>
        <div class="swal-input-container">
          <textarea id="Note" rows="4">${rowData.notes ?? rowData.notes}</textarea>
        </div>
        <div class="swal-input-container">
          <label for="Email" style="color: Black; font-weight: bold;">Notification Email:</label>
        </div>
        <div class="swal-input-container">
          <input id="Email" type="text" value="${rowData.employee.email}" required>
        </div>
      `,
        onOpen: () => {
          console.log("pass row is:", rowData);
        },
        preConfirm: () => {
          const Email = (document.getElementById('Email') as HTMLInputElement)?.value;
          const Note = (document.getElementById('Note') as HTMLInputElement)?.value;
          return { Note, Email };
        },
        showCancelButton: true,
        focusConfirm: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'custom-swal-container',
          confirmButton: 'custom-swal-confirm-button',
          cancelButton: 'custom-swal-cancel-button'
        }
      });
  
      if (result.isConfirmed) {
        const { Email = "", Note = "" } = result.value || {};
      
        await ApiRequest({
          url: `${baseUrl}Customer/UpdateCustomerStatus?id=${rowData.customerId}&statusId=${statusId}&note=${Note}`,
          method: 'put'
        });
        if (isValidEmail(Email))
          sendEmailToSales(rowData, Email, Note , statusId);
        resetData();
      }
      
    
  };
  
     
  const actionButtons: any = [
    {
      icon: 'ghost', //Button attr of Ant design (danger, ghost)
      tooltip: 'Attach',
      isFreeAction: false,
      onClick: (event: any, rowData: any) => {
        openDialog(attachmentManagementDialog, rowData)
      }
    },
    {
      icon: 'ghost', //Button attr of Ant design (danger, ghost)
      tooltip: 'Quote',
      isFreeAction: false,
      onClick: (event: any, rowData: any) => {
        openDialog(quotationManagementDialog, rowData)
      }
    },
    {
      icon: 'ghost', //Button attr of Ant design (danger, ghost)
      tooltip: 'Order',
      isFreeAction: false,
      onClick: (event: any, rowData: any) => {
        openDialog(orderManagementDialog, rowData)
      }
    },
    {
      icon: 'ghost', //Button attr of Ant design (danger, ghost)
      tooltip: 'Addr',
      isFreeAction: false,
      onClick: (event: any, rowData: any) => {
        openDialog(extraAddrDialog, rowData)
      }
    },
    {
      icon: 'ghost', //Button attr of Ant design (danger, ghost)
      tooltip: <ContactsOutlined />,
      isFreeAction: false,
      onClick: (event: any, rowData: any) => {
        openDialog(contactDialog, rowData)
      }
    }
  ]

  const openDialog = (dialogFn: any, rowData: any) => {
    setDialogContent(dialogFn(rowData.customerId))
    setDialogTitle(rowData.company + ' -- ' + rowData.customerCode)
    setOpen(true)
  }
  const getExtraButton = () => {
    const newCol = {
      title: 'Button',
      field: 'Buttons',
      editable: 'never',
      render: (rowData: any) => (
        !props.salesView ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>

              <Button
                key={getRandomKey()}
                type="primary"
                style={{ marginBottom: '10px', backgroundColor: '#4CAF50', borderColor: '#4CAF50', color: '#FFFFFF' }}
                onClick={(event: any) => {
                  openDialog(attachmentManagementDialog, rowData);
                }}
              >
                Attachment
              </Button>
              <Button
                key={getRandomKey()}
                type="ghost"
                style={{ marginBottom: '10px', backgroundColor: '#F44336', borderColor: '#F44336', color: '#FFFFFF' }}
                onClick={(event: any) => {
                  openDialog(quotationManagementDialog, rowData);
                }}
              >
                Quotes
              </Button>

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
              <Button
                key={getRandomKey()}
                type="primary"
                style={{ marginBottom: '10px', backgroundColor: '#F44336', borderColor: '#F44336', color: '#FFFFFF' }}
                onClick={(event: any) => {
                  openDialog(orderManagementDialog, rowData);
                }}
              >
                Orders
              </Button>
              <Button
                key={getRandomKey()}
                type="ghost"
                style={{ marginBottom: '10px', backgroundColor: '#2196F3', borderColor: '#2196F3', color: '#FFFFFF' }}
                onClick={(event: any) => {
                  openDialog(extraAddrDialog, rowData);
                }}
              >
                Address
              </Button>
              <Button
                key={getRandomKey()}
                type="primary"
                style={{ marginBottom: '10px', backgroundColor: '#2196F3', borderColor: '#2196F3', color: '#FFFFFF' }}
                onClick={(event: any) => {
                  openDialog(contactDialog, rowData);
                }}
              >
                Contacts
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              key={getRandomKey()}
              type="primary"
              style={{ marginBottom: '10px', backgroundColor: '#FF9800', borderColor: '#2196F3', color: '#FFFFFF' }}
              onClick={(event: any) => {
                openDialog(extraAddrDialog, rowData);
              }}
            >
              Address
            </Button>
          </div>
        )

      ),
    };
    return newCol;
  };
  
  
  const getCustomerManagementColumnModel = () =>{
    let ret =
    CustomerManagementColumnModel({updateCustomerStatus: updateCustomerStatus, isSalesCustomer: !!props.salesId, isSalesView: props.salesView});
    console.log(ret)
    ret.push(getExtraButton())
    console.log(ret)
    return ret
  } 
  return (
    <div className="customer">
      <CommonTablePage
        urlInfoKey={ urlKey.Customer }
        title={props.salesView ? 'Customer' : ((props.salesId ? '' : 'All ') + 'Customer Management')}
        column={getCustomerManagementColumnModel() }
        mappingUpdateData={(dataDetail: any, type: any) => {
          console.log(dataDetail)
          dataDetail.deliveryFeeFlag = dataDetail.flagId  //patch for deliveryFeeFlagId
          // dataDetail.showPriceOnPackingSlip = dataDetail.showPriceOnPackingSlip==true?1:0;
          if (type === urlType.Update && props.salesView) {
            return
          }
          isCreate = type === urlType.Create
          if (props.salesId && isCreate) {
            dataDetail.employeeId = props.salesId
            dataDetail.paymentCycleId = 6
            dataDetail.statusId = 3
            dataDetail.leadRating = 3
          }
          if (!dataDetail.leadRating) {
            dataDetail.leadRating = 3
          }
          if (dataDetail.leadRating > 5) {
            SweetAlertService.errorMessage('Rating must be lower equal than 5.')
            return
          }
          return dataDetail
        }}
        mappingRenderData={(data: any) => {
          if (isCreate) {
            const newCustomer = data[data.length - 1]
            openDialog(extraAddrDialog, newCustomer)
            ApiRequest({url: 'OnlineUser/AddOnlineUser', method: 'post', data: {
              userName: newCustomer.customerNumber.toString(),
              password: '123456',
              customerId: newCustomer.customerId
            }})
          }
          let returnData = data.map((row: any) => ({
            ...row,
            createdAtDate: row.createdAt && (new Date(row.createdAt + '.000Z')).toDateString(),
            flagId : row.deliveryFeeFlag 
          }))
          if (props.salesId) {
            returnData = returnData.filter((row: any) => row.employeeId === props.salesId)
          }
          console.log(returnData)
          return returnData
        }}
        triggerResetData={triggerResetData}
        isNotAddable={props.salesView}
        isNotDeletable={props.salesView}
        isNotEditable={props.salesView}
        columnsButton={true}
        // actionButtons={props.salesView ? actionButtons.filter((row: any) =>{ return row.tooltip === 'Addr'}) : actionButtons}
      />
      <CommonDialog open={open} title={dialogTitle} onDialogClose={() => setOpen(false)} dialogContent={dialogContent} />
    </div>
  )
}

export default CustomerManagementPage